import { useCallback } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { useAssociatedServiceTracking } from '../hooks/useAssociatedServiceTracking';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaImage } from 'src/libs/ui/pepita-image';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectInteractive,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';

import css from './styles.module.scss';

export interface ServiceCardBlockProps {
  partner: string;
  cover: { x1: string; x2: string };
  href:
    | {
        fr: string;
        en: string;
        de: string;
        pt: string;
        es: string;
        ru: string;
        it: string;
        el: string;
      }
    | string;
  title: string;
  subtitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  glyph: any; //type of svg
  cta: string;
  text?: string;
}

export function ServiceCardBlock({
  items,
}: {
  items: ServiceCardBlockProps[];
}) {
  const { locale } = useRouter();
  const { trans } = useTranslations();
  const { trackAssociatedServiceClick } = useAssociatedServiceTracking();

  const handleClick = useCallback(
    (service: ServiceCardBlockProps) => {
      trackAssociatedServiceClick(service.partner);
      window.open(
        locale && service.href[locale] ? service.href[locale] : service.href,
        '_blank'
      );
    },
    [locale, trackAssociatedServiceClick]
  );

  return (
    <>
      {items.map((item, i) => {
        return (
          <PepitaMediaObjectInteractive
            key={i}
            direction="col"
            customClass={css['in-serviceCardBlock']}
            onClick={() => handleClick(item)}
          >
            <PepitaFigure
              ratio="custom"
              figureContent={item.cover.x2}
              srcSet={`${item.cover.x1}, ${item.cover.x2} 2x`}
              imageCustomClass={css['in-serviceCardBlock__img']}
              alt={item.partner}
              width={290}
              height={156}
            />
            <PepitaMediaObjectContent
              customClass={css['in-serviceCardBlock__content']}
            >
              <PepitaMediaObjectTop
                customClass={css['in-serviceCardBlock__top']}
              >
                <PepitaMediaObjectTitle
                  customClass={css['in-serviceCardBlock__title']}
                >
                  {item.title}
                </PepitaMediaObjectTitle>
                <p className={css['in-serviceCardBlock__subtitle']}>
                  {item.subtitle}
                </p>
              </PepitaMediaObjectTop>
              <PepitaMediaObjectBottom
                customClass={css['in-serviceCardBlock__bottom']}
              >
                <PepitaImage
                  alt={`${item.partner} ${trans('lbl_logo')}`}
                  src={item.glyph}
                  customClass="in-serviceCardBlock__gliph"
                  width={24}
                />
                <PepitaButton as="span" variant="link">
                  {item.cta}
                </PepitaButton>
              </PepitaMediaObjectBottom>
            </PepitaMediaObjectContent>
          </PepitaMediaObjectInteractive>
        );
      })}
    </>
  );
}
