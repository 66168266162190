import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { GATrackingEvent } from 'src/tracking/utils/enums';
import type { TrackBannerAppActionArgs } from '../tracking/types';

export const useAppBannerTracking = () => {
  const sendGA4Event = useSendGA4Event();
  const { locale } = useRouter();
  const trackAppBannerAction = useCallback(
    ({ action, platform }: TrackBannerAppActionArgs) => {
      sendGA4Event(GATrackingEvent.BANNER_APP, {
        language: locale,
        os: platform,
        action,
      });
    },
    [locale, sendGA4Event]
  );

  return { trackAppBannerAction };
};
