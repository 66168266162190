import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL, alternateExternalUrl } from 'src/utils/url';

import cover_service_devenir_prop_2x from './images/cover_service_devenir_prop_2x.png';
import cover_enovos from './images/cover_services_enovos.jpg';
import cover_enovos_2x from './images/cover_services_enovos_2x.jpg';
import cover_immotopApp from './images/cover_services_immotopApp.jpg';
import cover_immotopApp_2x from './images/cover_services_immotopApp_2x.jpg';
import cover_immotopEstimation from './images/cover_services_immotopEstimation.jpg';
import cover_immotopEstimation_2x from './images/cover_services_immotopEstimation_2x.jpg';
import cover_lalux from './images/cover_services_lalux.jpg';
import cover_lalux_2x from './images/cover_services_lalux_2x.jpg';
import cover_orange_de from './images/cover_services_orange_de.png';
import cover_orange_en from './images/cover_services_orange_en.png';
import cover_orange_fr from './images/cover_services_orange_fr.png';
import cover_orange_pt from './images/cover_services_orange_pt.png';
import cover_rocheBobois from './images/cover_services_rocheBobois.jpg';
import cover_rocheBobois_2x from './images/cover_services_rocheBobois_2x.jpg';
import cover_spuerkeess from './images/cover_services_spuerkeess.jpg';
import cover_spuerkeess_2x from './images/cover_services_spuerkeess_2x.jpg';
import glyph_become_owner from './images/glyph_become_owner.svg';
import glyph_enovos from './images/glyph_enovos.svg';
import glyph_immotop from './images/glyph_immotop.svg';
import glyph_lalux from './images/glyph_lalux.svg';
import glyph_orange from './images/glyph_orange.svg';
import glyph_rocheBobois from './images/glyph_rocheBobois.svg';
import glyph_spuerkeess from './images/glyph_spuerkeess.svg';

const spuerkeessLink = {
  fr: 'https://www.spuerkeess.lu/fr/logement?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-FR',
  en: 'https://www.spuerkeess.lu/en/housing/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-EN',
  de: 'https://www.spuerkeess.lu/de/immobiliendarlehen/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-DE',
  pt: 'https://www.spuerkeess.lu/fr/logement?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-PT',
  es: 'https://www.spuerkeess.lu/en/housing/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-ES',
  ru: 'https://www.spuerkeess.lu/en/housing/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-RU',
  it: 'https://www.spuerkeess.lu/en/housing/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-IT',
  el: 'https://www.spuerkeess.lu/en/housing/?utm_campaign=PLOPartenariats-Links&utm_source=Immotop&utm_medium=Link&utm_content=Financer-Link-EL',
};

const laluxLink = {
  fr: 'https://www.lalux.lu/fr/particuliers/assurances/easyprotect-habitation?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=FR',
  en: 'https://www.lalux.lu/en/customers/insurances/easyprotect-home?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=EN',
  de: 'https://www.lalux.lu/de/privatkunden/versicherungen/easyprotect-haus-und-wohnung?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=DE',
  pt: 'https://www.lalux.lu/fr/particuliers/assurances/easyprotect-habitation?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=PT',
  es: 'https://www.lalux.lu/en/customers/insurances/easyprotect-home?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=ES',
  ru: 'https://www.lalux.lu/en/customers/insurances/easyprotect-home?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=RU',
  el: 'https://www.lalux.lu/en/customers/insurances/easyprotect-home?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=EL',
  it: 'https://www.lalux.lu/en/customers/insurances/easyprotect-home?utm_source=immotop&utm_medium=banner_546x340&utm_campaign=HAB2022&utm_content=IT',
};

const orangeLink = {
  fr: 'https://www.orange.lu/fr/landing-page/nouveaux-arrivants/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=FR',
  en: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=ENG',
  de: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=DE',
  pt: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=PT',
  es: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=ES',
  ru: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=RU',
  el: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=EL',
  it: 'https://www.orange.lu/en/landing-page/moving-to-luxembourg/?utm_source=immotop&utm_medium=homepage&utm_campaign=NA&utm_term=2023&utm_content=IT',
};

const enovosLink = {
  fr: 'https://welcome.enovos.lu/athome/fr?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=fr.energy.suggestedservices.',
  en: 'https://welcome.enovos.lu/athome/en?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=en.energy.suggestedservices.',
  de: 'https://welcome.enovos.lu/athome/de?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=de.energy.suggestedservices.',
  pt: 'https://welcome.enovos.lu/athome/fr?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=fr.energy.suggestedservices.',
  es: 'https://welcome.enovos.lu/athome/en?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=es.energy.suggestedservices.',
  ru: 'https://welcome.enovos.lu/athome/en?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=ru.energy.suggestedservices.',
  el: 'https://welcome.enovos.lu/athome/en?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=el.energy.suggestedservices.',
  it: 'https://welcome.enovos.lu/athome/en?utm_medium=cpc&utm_source=immotop&utm_campaign=lu.digitalmedia.brand.energy.&utm_term=general.&utm_content=it.energy.suggestedservices.',
};

const houseFinancingLink = {
  fr: 'https://pro.immotop.lu/financement/',
  en: 'https://pro.immotop.lu/en/financement/',
  de: 'https://pro.immotop.lu/de/financement/',
  pt: 'https://pro.immotop.lu/pt/financement/',
  es: 'https://pro.immotop.lu/en/financement/',
  ru: 'https://pro.immotop.lu/en/financement/',
  el: 'https://pro.immotop.lu/en/financement/',
  it: 'https://pro.immotop.lu/en/financement/',
};

const rocheBoboisLink = {
  fr: 'https://www.roche-bobois.com/fr-LU/service-conseil-decoration/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  en: 'https://www.roche-bobois.com/service-conseil-decoration/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  de: 'https://www.roche-bobois.com/de-DE/online-dekorationsberatung-deutschland/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  pt: 'https://www.roche-bobois.com/en-PT/service-conseil-decoration/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  es: 'https://www.roche-bobois.com/es-ES/servicio-asesoramiento-decoracion-espana/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  ru: 'https://www.roche-bobois.com/en-RU/service-conseil-decoration/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  el: 'https://www.roche-bobois.com/en-GR/service-conseil-decoration/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
  it: 'https://www.roche-bobois.com/it-IT/serivizio-consulenza-arredo-italia/service-decoration.html?utm_source=immotop.lu&utm_medium=banner&utm_content=servicesassocies-rochebobois',
};

const orangeCoverImages = {
  fr: cover_orange_fr,
  en: cover_orange_en,
  de: cover_orange_de,
  pt: cover_orange_pt,
};

interface MultilingualLinks {
  fr: string;
  en: string;
  de: string;
  pt: string;
  es: string;
  ru: string;
  el: string;
  it: string;
}

export interface AssociatedService {
  partner: string;
  glyph: string;
  href: MultilingualLinks | string;
  text?: string;
  cover: { x1: string; x2: string };
  title: string;
  subtitle: string;
  cta: string;
}

const evaluateBuildingUrl = getProductConfig('evaluateBuildingUrl');

export const useServicesData = (): AssociatedService[] => {
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter();

  const orangeCoverLanguage = (locale || defaultLocale) as Language;
  const orangeCover =
    orangeCoverImages[orangeCoverLanguage] || orangeCoverImages['en'];
  const immotopAppsLink = alternateAbsoluteURL(
    '/mobile/',
    defaultLocale as Language,
    locale as Language
  );

  const immotopEstimationLink =
    evaluateBuildingUrl &&
    alternateExternalUrl(
      evaluateBuildingUrl,
      defaultLocale as Language,
      locale as Language
    );

  return [
    {
      partner: 'spuerkeess',
      glyph: glyph_spuerkeess,
      href: spuerkeessLink,
      text: trans('lbl_finance'),
      cover: { x1: cover_spuerkeess.src, x2: cover_spuerkeess_2x.src },
      title: trans('lu_associated_services_title_mortgage'),
      subtitle: trans('lu_associated_services_subtitle_mortgage'),
      cta: trans('act_more_info'),
    },
    {
      partner: 'lalux',
      glyph: glyph_lalux,
      href: laluxLink,
      text: trans('lbl_insurance'),
      cover: { x1: cover_lalux.src, x2: cover_lalux_2x.src },
      title: trans('lu_associated_services_title_insurance'),
      subtitle: trans('lu_associated_services_subtitle_insurance'),
      cta: trans('act_more_info'),
    },
    {
      partner: 'orange',
      glyph: glyph_orange,
      href: orangeLink,
      text: trans('lbl_telecom'),
      cover: {
        x1: orangeCover.src,
        x2: orangeCover.src,
      },
      title: trans('lu_associated_services_title_telecom'),
      subtitle: trans('lu_associated_services_subtitle_telecom'),
      cta: trans('act_more_info'),
    },
    {
      partner: 'enovos',
      glyph: glyph_enovos,
      href: enovosLink,
      text: trans('lbl_energy'),
      cover: { x1: cover_enovos.src, x2: cover_enovos_2x.src },
      title: trans('lu_associated_services_title_energy'),
      subtitle: trans('lu_associated_services_subtitle_energy'),
      cta: trans('act_more_info'),
    },
    {
      partner: 'Roche Bobois',
      glyph: glyph_rocheBobois,
      href: rocheBoboisLink,
      text: trans('lu_lbl_furniture'),
      cover: { x1: cover_rocheBobois.src, x2: cover_rocheBobois_2x.src },
      title: trans('lu_associated_services_title_furniture'),
      subtitle: trans('lu_associated_services_subtitle_furniture'),
      cta: trans('act_more_info'),
    },
    {
      partner: 'Immotop Financement',
      glyph: glyph_become_owner,
      href: houseFinancingLink,
      text: trans('lu_associated_services_title_becoming_homeowner'),
      cover: {
        x1: cover_service_devenir_prop_2x.src,
        x2: cover_service_devenir_prop_2x.src,
      },
      title: trans('lu_associated_services_title_becoming_homeowner'),
      subtitle: trans('lu_associated_services_subtitle_becoming_homeowner'),
      cta: trans('act_more_info'),
    },

    {
      partner: 'Immotop Apps',
      glyph: glyph_immotop,
      href: immotopAppsLink,
      text: trans('lu_associated_services_title_app'),
      cover: { x1: cover_immotopApp.src, x2: cover_immotopApp_2x.src },
      title: trans('lu_associated_services_title_app'),
      subtitle: trans('lu_associated_services_subtitle_app'),
      cta: trans('act_more_info'),
    },
    ...(immotopEstimationLink
      ? [
          {
            partner: 'Immotop Estimation',
            cover: {
              x1: cover_immotopEstimation.src,
              x2: cover_immotopEstimation_2x.src,
            },
            glyph: glyph_immotop,
            href: immotopEstimationLink,
            text: trans('lbl_evaluate_house'),
            title: trans('lu_associated_services_title_appraisal'),
            subtitle: trans('lu_associated_services_subtitle_appraisal'),
            cta: trans('act_more_info'),
          },
        ]
      : []),
  ];
};
