export enum RELATEDLINKS_TYPE {
  FEATURES_GROUP = 'feature-group',
}

export type RelatedLinksData = {
  text: string;
  link: {
    url: string;
  };
};

export type RelatedLinksProps = {
  type?: RELATEDLINKS_TYPE;
  titleList?: string;
  data: RelatedLinksData[];
};
