import type { ElementType } from 'react';
import { useRouter } from 'next/router';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import type { RelatedLinksProps } from 'src/types/related-links';
import { RELATEDLINKS_TYPE } from 'src/types/related-links';

import css from './index.module.scss';

function RelatedLinksItem({ type, titleList, data }: RelatedLinksProps) {
  const router = useRouter();

  const getTitle = () => {
    if (titleList) {
      if (titleList.indexOf(':') === -1) {
        return titleList;
      }

      return titleList.replace(':', '');
    }

    return '';
  };

  const title = getTitle();

  return (
    <div className={css['in-relatedLinks__content']}>
      {title && (
        <>
          <h3 className={css['in-relatedLinks__titleList']}>{title}</h3>
          {': '}
        </>
      )}
      <PepitaList
        customClass={css['in-relatedLinks__list']}
        type="comma"
        items={data.map((item, i) => {
          const link =
            item?.link?.url && type === RELATEDLINKS_TYPE.FEATURES_GROUP
              ? Object.assign(item?.link, {
                  onClick: (event) => {
                    event.preventDefault();
                    const tempLink = document.createElement('a');

                    tempLink.href = item?.link?.url;
                    router.push(tempLink.pathname);
                  },
                })
              : item.link;

          return (
            <PepitaListItem
              key={i}
              text={item.text}
              link={{
                href: link.url,
              }}
              customClass={css['in-relatedLinks__link']}
            />
          );
        })}
      />
    </div>
  );
}

export function RelatedLinks({
  customClass,
  title,
  TitleTag = 'h2',
  config,
  trackingKey,
}: {
  customClass?: string;
  title?: string;
  TitleTag?: ElementType;
  config: RelatedLinksProps[];
  trackingKey?: string;
}) {
  if (!(config?.length > 0)) return null;

  return (
    <div
      className={customClass}
      data-cy="related-links"
      data-tracking-key={trackingKey}
    >
      {title && (
        <TitleTag className={css['in-relatedLinks__title']}>{title}</TitleTag>
      )}
      {config.map((item, i) => {
        return (
          <RelatedLinksItem
            key={i}
            type={item.type}
            titleList={item.titleList}
            data={item.data}
          />
        );
      })}
    </div>
  );
}
