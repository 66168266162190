import { useEffect, useState } from 'react';

import { getProductConfig } from 'src/config/product';

export type Platform = 'Android' | 'iOS' | 'Huawei';
interface DeviceInfo {
  platform: Nullable<Platform>;
  appUrl: Nullable<string>;
  storeUrl: Nullable<string>;
}

export function useAppLink(linkToCurrentPage?: boolean) {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    platform: null,
    appUrl: null,
    storeUrl: null,
  });

  const appStores = getProductConfig('appStores');
  const appConfig = getProductConfig('appConfig');

  const domainName = getProductConfig('domainName');

  let currentPageLink: Nullable<string> = null;

  function handleClick(
    platform: Nullable<Platform>,
    storeUrl: Nullable<string>
  ) {
    if (!platform || !storeUrl) return;

    if (!deviceInfo.appUrl) {
      window.location.replace(storeUrl);
    } else {
      window.location.replace(deviceInfo.appUrl);
    }
  }

  useEffect(() => {
    const isHuawei = /hmscore/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad/i.test(navigator.userAgent);

    if (linkToCurrentPage) {
      currentPageLink = `www.${domainName}${document.location.pathname}${document.location.search}`;
    }

    if (appConfig && appStores) {
      if (isHuawei && appConfig?.huawei) {
        setDeviceInfo({
          platform: 'Huawei',
          appUrl: null,
          storeUrl: `appmarket://details?id=${appConfig.huawei.app_package}`,
        });
      } else if (isAndroid && appStores?.android && appConfig?.android) {
        const link = currentPageLink || appConfig.android.app_host;

        setDeviceInfo({
          platform: 'Android',
          appUrl: `intent://${link}#Intent;scheme=${appConfig.android.uri_scheme};package=${appConfig.android.app_package};end`,
          storeUrl: appStores.android,
        });
      } else if (isIOS && appStores?.iOS && appConfig?.iOS) {
        const link = currentPageLink || '';

        setDeviceInfo({
          platform: 'iOS',
          appUrl: `${appConfig.iOS.app_name}://${link}`,
          storeUrl: appStores.iOS,
        });
      }
    }
  }, []);

  return {
    platform: deviceInfo.platform,
    storeUrl: deviceInfo.storeUrl,
    appUrl: deviceInfo.appUrl,
    openAppOrStore: () => handleClick(deviceInfo.platform, deviceInfo.storeUrl),
    appIcon: appConfig?.img,
  };
}
