import { useCallback } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { useAssociatedServiceTracking } from '../hooks/useAssociatedServiceTracking';
import type { AssociatedService } from '../useServicesData';
import { useServicesData } from '../useServicesData';

import { PepitaImage } from 'src/libs/ui/pepita-image';
import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import css from './styles.module.scss';

export function AssociatedServicesList({ isCompact }: { isCompact: boolean }) {
  const servicesData = useServicesData();
  const { locale } = useRouter();

  const { trackAssociatedServiceClick } = useAssociatedServiceTracking();

  const handleClick = useCallback(
    (service: AssociatedService) => {
      trackAssociatedServiceClick(service.partner);
      window.open(
        locale && service.href[locale] ? service.href[locale] : service.href,
        '_blank'
      );
    },
    [locale, trackAssociatedServiceClick]
  );

  return (
    <PepitaList customClass={css['in-associatedServicesList']}>
      {servicesData
        .filter((v) => v.text !== undefined)
        .map((item, i) => {
          return (
            <PepitaListItem
              key={i}
              customClass={clsx(
                css['in-associatedServicesList__item'],
                isCompact && css['is-compact']
              )}
              onClick={() => handleClick(item)}
            >
              <PepitaImage
                src={item.glyph}
                alt={item.partner}
                customClass={css['in-associatedServicesList__glyph']}
                width={24}
              />
              {item.text}
            </PepitaListItem>
          );
        })}
    </PepitaList>
  );
}
