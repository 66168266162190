import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { useServicesData } from '../useServicesData';

import { ServiceCardBlock } from '../ServiceCardBlock';

import css from './styles.module.scss';

export function AssociatedCards({
  titleClass,
  customClass,
  trackingKey,
}: {
  titleClass?: string;
  customClass?: string;
  trackingKey?: string;
}) {
  const { trans } = useTranslations();
  const servicesData = useServicesData();

  return (
    <div className={customClass} data-tracking-key={trackingKey}>
      <p className={clsx(css['in-associatedCards__title'], titleClass)}>
        {trans('lbl_associated_services', { capitalize: true })}
      </p>
      <div className={css['in-associatedCards__group']}>
        <ServiceCardBlock items={servicesData} />
      </div>
    </div>
  );
}
