import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { EventName } from 'src/tracking/utils/enums';

export const useAssociatedServiceTracking = () => {
  const track = useTrack();
  const { user } = useAuthContext();

  const trackAssociatedServiceClick = useCallback(
    (serviceName: string) => {
      track(EventName.ASSOCIATED_SERVICE_CLICKED, {
        'Is User Logged': Boolean(user),
        'User ID': user ? user.uuid : undefined,
        'Service Name': serviceName,
      });
    },
    [track, user]
  );

  return { trackAssociatedServiceClick };
};
