import { useEffect, useState } from 'react';
import { getCookie, setCookie } from '@pepita/lotrash';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { displayGOTAtom } from 'src/atoms/displayGOTAtom';

import { useAppLink } from 'src/hooks/useAppLink';
import { useAppBannerTracking } from './hooks/useAppBannerTracking';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { AppBannerAction } from './tracking/enums';

import { useIsCookieBannerVisible } from '../CookieManager/CookieProvider';

import css from './index.module.scss';
const DOWNLOAD_APP_COOKIE = 'downloadApp';

export type AppBannerProps = {
  withUserTab?: boolean;
};

export function AppBanner({ withUserTab }: AppBannerProps) {
  const { trans } = useTranslations();
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { trackAppBannerAction } = useAppBannerTracking();

  // with true param redirect to the current page on the app
  const { platform, storeUrl, openAppOrStore, appUrl, appIcon } =
    useAppLink(true);

  const handleRemove = () => {
    setShowBanner(false);
    setCookie(DOWNLOAD_APP_COOKIE, '1', 1);
  };

  const handleClose = () => {
    handleRemove();
    trackAppBannerAction({ action: AppBannerAction.CLOSE, platform });
  };

  const isCookieBannerVisible = useIsCookieBannerVisible();

  const displayGOT = useAtomValue(displayGOTAtom);

  useEffect(() => {
    // on safari only native banners are displayed
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;

    // check also if the cookiebar has just been shown, to avoid showing too many banners consecutively
    setShowBanner(
      !isCookieBannerVisible &&
        !getCookie(DOWNLOAD_APP_COOKIE) &&
        Boolean(platform) &&
        Boolean(storeUrl) &&
        !isSafari &&
        displayGOT === 'hidden'
    );
  }, [platform, storeUrl, isCookieBannerVisible, displayGOT]);

  useEffect(() => {
    if (showBanner) {
      // send an event and sets cookie when banner app is shown
      trackAppBannerAction({ action: AppBannerAction.IMPRESSION, platform });
    }
  }, [showBanner]);

  if (!showBanner) return null;

  return (
    <div
      className={clsx(
        css['in-appBanner'],
        withUserTab && css['in-appBanner--withUserTab']
      )}
    >
      <button onClick={handleClose} className={css['in-appBanner__close']}>
        <PepitaIcon name="cross" />
      </button>
      <div className={css['in-appBanner__content']}>
        <img
          src={appIcon}
          alt={trans('act_use_the_app')}
          width="48"
          height="48"
          className={css['in-appBanner__image']}
        />
        <span>{trans('new_app_banner_text')}</span>
      </div>
      {platform === 'iOS' ? (
        <div className={css['in-appBanner__actions']}>
          <PepitaButton
            customClass={css['in-appBanner__button']}
            small
            onClick={() => {
              window.location.replace(appUrl as string);
              handleRemove();
              trackAppBannerAction({
                action: AppBannerAction.DOWNLOAD,
                platform,
              });
            }}
          >
            {trans('act_open')}
          </PepitaButton>
          <PepitaButton
            customClass={css['in-appBanner__button']}
            small
            variant="accent"
            onClick={() => {
              window.location.replace(storeUrl as string);
              handleRemove();
              trackAppBannerAction({
                action: AppBannerAction.DOWNLOAD,
                platform,
              });
            }}
          >
            {trans('act_download')}
          </PepitaButton>
        </div>
      ) : (
        <PepitaButton
          customClass={css['in-appBanner__button']}
          small
          variant="accent"
          block
          onClick={() => {
            handleRemove();
            trackAppBannerAction({
              action: AppBannerAction.DOWNLOAD,
              platform,
            });
            openAppOrStore();
          }}
        >
          {trans('act_use_the_app')}
        </PepitaButton>
      )}
    </div>
  );
}
